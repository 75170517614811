import ValuationApi from '@/api/ValuationApi'
import { AutomationRuleListType } from '#/EntityTypes'
import { valuationFilterSaveType } from '@/config/valuation'
import {
  AssetsItemType,
  AssetsListType,
  FutureAdjustmentListType,
  FutureRawDataListType,
  OptionsRawDataListType,
  OptionsAdjustmentListType,
  PriceProviderType,
  ValuationFilterType,
  ValuationFilterTypeInfo
} from '#/ValuationTypes'

export const useValuationStore = defineStore({
  id: 'valuationStore',
  state: () => {
    const valuationState: {
      priceProviderList: PriceProviderType
      derivativeAdjustmentAutomationRuleList: AutomationRuleListType
      valuationFilterList: ValuationFilterTypeInfo[]
      assetsDataList: AssetsListType
      futureAdjustmentList: FutureAdjustmentListType
      futureRawDataList: FutureRawDataListType
      optionsAdjustmentList: OptionsAdjustmentListType
      optionsRawDataList: OptionsRawDataListType
    } = {
      priceProviderList: {
        total: 0,
        totalCount: 0,
        list: []
      },
      derivativeAdjustmentAutomationRuleList: {
        list: [],
        totalCount: 0
      },
      valuationFilterList: [],
      assetsDataList: {
        totalCount: 0,
        list: [] as Array<AssetsItemType>
      },
      futureAdjustmentList: {
        totalCount: 0,
        filteredCount: 0,
        list: []
      },
      futureRawDataList: {
        totalCount: 0,
        list: []
      },
      optionsAdjustmentList: {
        totalCount: 0,
        filteredCount: 0,
        list: []
      },
      optionsRawDataList: {
        totalCount: 0,
        list: []
      }
    }
    return valuationState
  },
  actions: {
    /**
     * 编辑调价记录列表筛选器
     * @param {number} entityId 主体id
     * @param {ValuationFilterType} data 过滤器数据
     * @param {string} type 过滤器类型 valuationFilterSaveType
     */
    editCalculationFilter(
      entityId: string,
      data: ValuationFilterType,
      fields?: string[],
      currentType = valuationFilterSaveType.FUTURE
    ) {
      const currentSaveInfo = this.valuationFilterList.find((item) => item.type === currentType)
      let filterLength = Object.keys(data).length
      if (fields?.length) {
        const filterKeys = Object.keys(data)
        fields.forEach((field) => {
          if (filterKeys.includes(field)) {
            filterLength -= 1
          }
        })
        filterLength = filterLength < 0 ? 0 : filterLength
      }
      if (currentSaveInfo) {
        const currentSaveList = currentSaveInfo?.list || []
        const currentIndex = currentSaveList.findIndex((item: any) => item.entityId === entityId)

        if (currentIndex !== -1) {
          if (!filterLength) {
            currentSaveList.splice(currentIndex, 1)
          } else {
            currentSaveList.splice(currentIndex, 1, {
              entityId,
              total: filterLength,
              data
            })
          }
        } else if (filterLength) {
          currentSaveList.push({ entityId, total: filterLength, data })
        }
      } else {
        this.valuationFilterList.push({
          type: currentType,
          list: [
            {
              entityId,
              total: filterLength,
              data
            }
          ]
        })
      }
    },
    /**
     * 获取Derivative Adjustment自动化规则列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page
     * @property {number} params.limit
     */
    async fetchDerivativeAdjustmentAutomationRuleList(entityId: string, params: object) {
      try {
        const response = await ValuationApi.getDerivativeAdjustmentAutomationRuleList(entityId, params)
        this.derivativeAdjustmentAutomationRuleList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取资产列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     */
    async fetchAssetsDataList(entityId: string, params: object) {
      try {
        const respondData = await ValuationApi.getAssetsTableList(entityId, params)
        this.assetsDataList = respondData.data
        return respondData.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取价格源列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     */
    async fetchPriceProviderList(entityId: string, params: object) {
      try {
        const respondData = await ValuationApi.getPriceProviderList(entityId, params)
        this.priceProviderList = respondData.data
        return respondData.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Future Adjustment列表
     * @param {string} entityId 主体id
     * @param {object} params  请求参数
     * @property {number} page 页码
     * @property {number} limit 每页数量
     * @property {string} sortField 排序字段
     * @property {string} sortDirection 排序方向
     */
    async fetchFutureAdjustmentList(entityId: string, params: object) {
      try {
        const respondData = await ValuationApi.getFutureAdjustmentList(entityId, params)
        this.futureAdjustmentList = respondData.data
        return respondData.data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    /**
     * 获取Future RawData列表
     * @param {string} entityId 主体id
     * @param {object} params  请求参数
     * @property {number} page 页码
     * @property {number} limit 每页数量
     * @property {string} sortField 排序字段
     * @property {string} sortDirection 排序方向
     */
    async fetchFutureRawDataList(entityId: string, params: object) {
      try {
        const respondData = await ValuationApi.getFutureFactList(entityId, params)
        this.futureRawDataList = respondData.data
        return respondData.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Options Adjustment列表
     * @param {string} entityId 主体id
     * @param {object} params  请求参数
     * @property {number} page 页码
     * @property {number} limit 每页数量
     * @property {string} sortField 排序字段
     * @property {string} sortDirection 排序方向
     */
    async fetchOptionsAdjustmentList(entityId: string, params: object) {
      try {
        const respondData = await ValuationApi.getOptionsAdjustmentList(entityId, params)
        this.optionsAdjustmentList = respondData.data
        return respondData.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Options RawData列表
     * @param {string} entityId 主体id
     * @param {object} params  请求参数
     * @property {number} page 页码
     * @property {number} limit 每页数量
     * @property {string} sortField 排序字段
     * @property {string} sortDirection 排序方向
     */
    async fetchOptionsRawDataList(entityId: string, params: object) {
      try {
        const respondData = await ValuationApi.getOptionsFactList(entityId, params)
        this.optionsRawDataList = respondData.data
        return respondData.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    clearPriceProviderList() {
      this.priceProviderList = {
        total: 0,
        totalCount: 0,
        list: []
      }
    },
    clearAssetsDataList() {
      this.assetsDataList = {
        totalCount: 0,
        list: []
      }
    },
    clearFutureAdjustmentList() {
      this.futureAdjustmentList = {
        totalCount: 0,
        filteredCount: 0,
        list: []
      }
    },
    clearOptionsAdjustmentList() {
      this.optionsAdjustmentList = {
        totalCount: 0,
        filteredCount: 0,
        list: []
      }
    },
    clearFutureRawDataList() {
      this.futureRawDataList = {
        totalCount: 0,
        list: []
      }
    },
    clearOptionsRawDataList() {
      this.optionsRawDataList = {
        totalCount: 0,
        list: []
      }
    }
  },
  getters: {},
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ['valuationFilterList']
      }
    ]
  }
})
